import { getViewshedState } from './viewshed-button-component.js'
import { getSlopeAngleRange } from './slope-angle-picker-component.js'
import { getElevationRange } from './elevation-picker-component.js'
import * as marketing from '../../utilities/marketing.js'
import stateService from '../../services/state-service.js'

export const ANGLE_MAP = {
  N: {
    id: 'N',
    start: 337,
    end: 22,
  },
  NE: {
    id: 'NE',
    start: 22,
    end: 67,
  },
  E: {
    id: 'E',
    start: 67,
    end: 112,
  },
  SE: {
    id: 'SE',
    start: 112,
    end: 157,
  },
  S: {
    id: 'S',
    start: 157,
    end: 202,
  },
  SW: {
    id: 'SW',
    start: 202,
    end: 247,
  },
  W: {
    id: 'W',
    start: 247,
    end: 292,
  },
  NW: {
    id: 'NW',
    start: 292,
    end: 337,
  },
}

const SELECTED = '-selected'
const HOVERED = '-hovered'
const SELECT_HOVERED = '-select-hovered'
class SlopeAspectRosePickerComponent extends HTMLElement {
  constructor() {
    super()

    // Attach a shadow root
    const shadowRoot = this.attachShadow({ mode: 'open' })
    shadowRoot.innerHTML = String.raw`
      <style>
        .float-right {
          position: absolute;
          right: 18px;
          top: 55px;
          font-size: 0.6em;
        }

        #compass {
          width: 275px;
          height: 275px;
          position: relative;
          margin: 30px 0 15px 15px;
        }

        .rose:hover {
          cursor: pointer;
        }

        .rose path {
          stroke: #FFF;
        }

        .rose path.-hovered {
          fill: #FFF;
          fill-opacity: 0.8;
        }

        .rose path.-selected {
          fill: #8b8b8b;
          fill-opacity: 1;
        }

        .rose path.-select-hovered {
          fill-opacity: 0.5;
        }
      </style>
  
      <div class="float-right" style="color: white">
        <span id="slope-aspect-display"></span>
      </div>

      <!-- compass -->
      <div id="compass">
        <svg
        class="rose"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="S"
          fill="#000"
          fill-opacity="0.25"
          class="rose-component"
          d="m11.853 33.095 6.104-15.093 6.34 15.02-12.444.073Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
        <path
          id="SW"
          fill="#000"
          fill-opacity="0.25"
          class="rose-component"
          d="m11.853 33.095 6.104-15.093-14.972 6.32 8.868 8.773Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
        <path
          id="W"
          fill="#000"
          fill-opacity="0.25"
          class="rose-component"
          d="m2.985 24.321 14.972-6.32L2.883 11.85l.102 12.471Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
        <path
          id="NW"
          fill="#000"
          fill-opacity="0.25"
          class="rose-component"
          d="m2.924 11.814 15.047 6.22-6.276-15.047-8.77 8.827Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
        <path
          id="SE"
          fill="#000"
          fill-opacity="0.25"
          class="rose-component"
          d="m33.067 24.195-15.046-6.22 6.275 15.047 8.77-8.827Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
        <path
          id="E"
          fill="#000"
          fill-opacity="0.25"
          class="rose-component -selected"
          d="m33.067 24.195-15.046-6.22 15-6.255.046 12.475Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
        <path
          id="NE"
          fill="#000"
          fill-opacity="0.25"
          class="rose-component -selected"
          d="m33.02 11.72-15 6.255 6.173-15.066 8.827 8.81Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
        <path
          id="N"
          fill="rgba(0, 0, 0)"
          fill-opacity="0.25"
          class="rose-component -selected"
          d="m24.138 2.914-6.104 15.093-6.34-15.02 12.444-.073Z"
          stroke-width="1"
          stroke-linejoin="round"
        />
      </svg>
        </div>
      </div>
    
      `
  }

  getState() {
    return stateService.getState('slopeAspectDegrees')
  }

  connectedCallback() {
    const paths = Array.from(
      this.shadowRoot.querySelectorAll('.rose-component')
    )

    const pathMouseoverHandler = this.handlePathMouseover.bind(this)
    const pathMouseoffHandler = this.handlePathMouseoff.bind(this)
    const pathClickHandler = this.handlePathClick.bind(this)
    const selectedAngles = stateService
      .getState('slopeAspectDegrees')
      .map((state) => state.id)

    paths.forEach((path) => {
      path.addEventListener('click', pathClickHandler)
      path.addEventListener('mouseover', pathMouseoverHandler)
      path.addEventListener('mouseout', pathMouseoffHandler)

      // make sure selected angles are highlighted
      if (selectedAngles.includes(path.id)) {
        path.classList.add(SELECTED)
      } else {
        path.classList.remove(SELECTED)
      }
    })
    this.updateDisplayList()
  }

  updateDisplayList() {
    const container = this.shadowRoot.getElementById('slope-aspect-display')
    const currState = stateService.getState('slopeAspectDegrees')
    const ids = currState.map((item) => item.id)
    container.textContent = ids.join(', ')
  }

  handlePathClick(e) {
    e.preventDefault()
    const id = e.target.id
    const targetRange = ANGLE_MAP[id]
    const currState = stateService.getState('slopeAspectDegrees')
    if (e.target.classList.contains(SELECTED)) {
      e.target.classList.remove(SELECTED)
    } else {
      e.target.classList.add(SELECTED)
    }
    const inState = currState.some((angles) => angles.id === id)
    if (inState) {
      const updatedState = currState.filter((angles) => angles.id !== id)
      stateService.setState('slopeAspectDegrees', updatedState)
    } else {
      const updatedState = [...currState, targetRange]
      stateService.setState('slopeAspectDegrees', updatedState)
    }

    document.dispatchEvent(new CustomEvent('slope-aspect-rose-changed'))
    this.updateDisplayList()
    this.sendMarketingEvent('slope aspect rose')
  }

  handlePathMouseover(e) {
    e.target.classList.contains(SELECTED)
      ? e.target.classList.add(SELECT_HOVERED)
      : e.target.classList.add(HOVERED)
  }

  handlePathMouseoff(e) {
    e.target.classList.contains(SELECTED)
      ? e.target.classList.remove(SELECT_HOVERED)
      : e.target.classList.remove(HOVERED)
  }

  /**
   * Update the selected range text at the top right
   */
  updateSelectedRangeText() {
    this.shadowRoot.getElementById('slope-aspect-1-display').textContent =
      selectedAngles.start
    this.shadowRoot.getElementById('slope-aspect-2-display').textContent =
      selectedAngles.end
  }

  sendMarketingEvent(origin) {
    const elevRange = getElevationRange()
    const slopeAngleRange = getSlopeAngleRange()
    marketing.logEvent('terrain_x_filters_modified', {
      modified_filter: 'slope aspect',
      filter_status: 'on',
      origin: origin,
      viewshed_state: getViewshedState(),
      slope_aspect: JSON.stringify(stateService.getState('slopeAspectDegrees')),
      slope_angle: `${slopeAngleRange.min}-${slopeAngleRange.max}`,
      elevation: `${elevRange.min}-${elevRange.max}`,
    })
  }
}

customElements.define(
  'slope-aspect-rose-picker-component',
  SlopeAspectRosePickerComponent
)
